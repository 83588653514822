import type { ApiFetch } from './main'
import type { ApiResponse } from '~/types/misc'
import type { StockCategory, StockItem, StockSuppliers } from '~/types/stock'

export interface StockAPI {
  getStockSuppliers: (
    offset?: number,
    limit?: number,
  ) => Promise<ApiResponse<StockSuppliers>>
  getStockSuppliersList: () => Promise<{
    value: string
    label: string
  }>
  exportStockSuppliers: () => Promise<Blob>
  updateStockSupplier: (
    id: string | null,
    data: StockSuppliers,
  ) => Promise<void>
  deleteStockSupplier: (id: string) => Promise<void>
  getStockCategories: (
    offset?: number,
    limit?: number,
  ) => Promise<ApiResponse<StockCategory>>
  updateStockCategory: (id: string | null, data: StockCategory) => Promise<void>
  getStockCategoriesList: () => Promise<{
    value: string
    label: string
  }>
  exportStockCategories: () => Promise<Blob>
  getStockCategory: (id: string) => Promise<StockCategory>
  deleteStockCategory: (id: string) => Promise<void>
  getStockItems: (
    offset?: number,
    limit?: number,
    category?: string,
  ) => Promise<ApiResponse<StockItem>>
  getStockItem: (id: string) => Promise<StockItem>
  updateStockItem: (id: string | null, data: StockItem) => Promise<void>
  exportStockItems: () => Promise<Blob>
  deleteStockItem: (id: string) => Promise<void>
}

export default (apiFetch: ApiFetch): StockAPI => ({
  // Stock Suppliers
  getStockSuppliers(offset, limit) {
    let url = `/stock/suppliers?`

    if (offset) {
      url += `offset=${offset}&`
    }

    if (limit) {
      url += `limit=${limit}&`
    }

    // Remove the trailing '&' or '?' if no parameters were added
    url = url.endsWith('&') || url.endsWith('?') ? url.slice(0, -1) : url

    return apiFetch(url)
  },

  updateStockSupplier(id, data) {
    if (id) {
      return apiFetch(`/stock/suppliers/${id}`, {
        method: 'PATCH',
        body: data,
      })
    }
    return apiFetch(`/stock/suppliers`, {
      method: 'POST',
      body: data,
    })
  },

  getStockSuppliersList() {
    return apiFetch(`/stock/suppliers/enumerate`)
  },

  exportStockSuppliers() {
    return apiFetch(`/stock/suppliers/export`)
  },

  deleteStockSupplier(id) {
    return apiFetch(`/stock/suppliers/${id}`, {
      method: 'DELETE',
    })
  },

  // Stock Categories

  getStockCategories(offset, limit) {
    let url = `/stock/categories?`

    if (offset) {
      url += `offset=${offset}&`
    }

    if (limit) {
      url += `limit=${limit}&`
    }

    // Remove the trailing '&' or '?' if no parameters were added
    url = url.endsWith('&') || url.endsWith('?') ? url.slice(0, -1) : url

    return apiFetch(url)
  },

  updateStockCategory(id, data) {
    if (id) {
      return apiFetch(`/stock/categories/${id}`, {
        method: 'PATCH',
        body: data,
      })
    }
    return apiFetch(`/stock/categories`, {
      method: 'POST',
      body: data,
    })
  },

  getStockCategoriesList() {
    return apiFetch(`/stock/categories/enumerate`)
  },

  exportStockCategories() {
    return apiFetch(`/stock/categories/export`)
  },

  getStockCategory(id) {
    return apiFetch(`/stock/categories/${id}`)
  },

  deleteStockCategory(id) {
    return apiFetch(`/stock/categories/${id}`, {
      method: 'DELETE',
    })
  },

  // Stock Items

  getStockItems(offset, limit, category) {
    let url = `/stock/items?`

    if (offset) {
      url += `offset=${offset}&`
    }

    if (limit) {
      url += `limit=${limit}&`
    }

    if (category) {
      url += `category=${category}&`
    }

    // Remove the trailing '&' or '?' if no parameters were added
    url = url.endsWith('&') || url.endsWith('?') ? url.slice(0, -1) : url

    return apiFetch(url)
  },

  getStockItem(id) {
    return apiFetch(`/stock/items/${id}`)
  },

  updateStockItem(id, data) {
    if (id) {
      return apiFetch(`/stock/items/${id}`, {
        method: 'PATCH',
        body: data,
      })
    }
    return apiFetch(`/stock/items`, {
      method: 'POST',
      body: data,
    })
  },

  exportStockItems() {
    return apiFetch(`/stock/items/export`)
  },

  deleteStockItem(id) {
    return apiFetch(`/stock/items/${id}`, {
      method: 'DELETE',
    })
  },
})
