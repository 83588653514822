import type { ApiFetch } from './main'
import type { ApiResponse } from '~/types/misc'
import type { Notification, Reminder } from '~/types/notification'

interface NotificationApiResponse<T> extends ApiResponse<T> {
  unread_count: number
}

export interface NotificationApi {
  getNotifications: (
    offset: number,
    limit: number,
  ) => Promise<NotificationApiResponse<Notification>>
  markNotificationAsRead: (remove?: boolean) => Promise<void>

  getReminders: (
    offset: number,
    limit: number,
    date_from?: string,
  ) => Promise<ApiResponse<Reminder>>
  addOrUpdateReminder: (reminder: Reminder, id?: string) => Promise<void>
  deleteReminder: (id: string) => Promise<void>
}

export default (apiFetch: ApiFetch): NotificationApi => ({
  getNotifications(offset, limit) {
    let url = `/notifications?`

    if (offset) {
      url += `offset=${offset}&`
    }

    if (limit) {
      url += `limit=${limit}&`
    }

    // Remove the trailing '&' or '?' if no parameters were added
    url = url.endsWith('&') || url.endsWith('?') ? url.slice(0, -1) : url

    return apiFetch(url)
  },

  markNotificationAsRead(remove: boolean = false) {
    return apiFetch(`/notifications/read${remove ? '?remove=true' : ''}`, {
      method: 'POST',
    })
  },

  getReminders(offset, limit, date_from) {
    let url = `/reminders?`

    if (offset) {
      url += `offset=${offset}&`
    }

    if (limit) {
      url += `limit=${limit}&`
    }

    if (date_from) {
      url += `date_from=${date_from}&`
    }

    // Remove the trailing '&' or '?' if no parameters were added
    url = url.endsWith('&') || url.endsWith('?') ? url.slice(0, -1) : url

    return apiFetch(url)
  },

  addOrUpdateReminder(reminder, id) {
    return apiFetch(`/reminders${id ? `/${id}` : ''}`, {
      method: id ? 'PATCH' : 'POST',
      body: reminder,
    })
  },

  deleteReminder(id) {
    return apiFetch(`/reminders/${id}`, {
      method: 'DELETE',
    })
  },
})
