import { defineStore } from 'pinia'
import type { Permission } from '~/types/misc'

import type { UserRootObject } from '~/types/user'

interface State {
  isLoggedIn: boolean | null
  auth: UserRootObject | undefined
  app: {
    themeColor: string
  }
}

export const useUserStore = defineStore('user', {
  state: (): State => ({
    isLoggedIn: null,
    auth: undefined,
    app: {
      themeColor: '#fff',
    },
  }),

  getters: {
    isClinicOwner(): boolean {
      return this.auth?.user?.id === this.auth?.user?.clinic?.owner
    },
    isClinicAdmin(): boolean {
      return this.auth?.user?.is_admin ?? false
    },
    hasPermission(): (permission: Permission) => boolean {
      return (permission) => {
        // Admins have all permissions
        if (this.auth?.user?.is_admin) {
          return true
        }

        return this.auth?.user?.permissions?.includes(permission) ?? false
      }
    },
    meilisearchKey(): string | undefined {
      return this.auth?.user?.search_key
    },
  },

  actions: {
    setUser(user: UserRootObject) {
      this.auth = user
    },
    setIsloggedIn(isLoggedIn: boolean) {
      this.isLoggedIn = isLoggedIn
    },
    logout() {
      this.isLoggedIn = false
      this.auth = undefined
      window.localStorage.removeItem('clinic')
    },
    changeThemeColor(color: string) {
      this.app.themeColor = color
    },

    async getUser() {
      const nuxtApp = useNuxtApp()
      await nuxtApp.$api
        .getCurrentUser()
        .then((response) => {
          // Check if localstorage exists in the user clinics
          const selectedClinic = localStorage.getItem('clinic')
          if (selectedClinic) {
            const clinicExists = response.clinics.find(
              (clinic) => clinic.id === selectedClinic,
            )
            if (!clinicExists) {
              localStorage.removeItem('clinic')
              refreshToHome()
            }
          }

          this.setUser(response)
          this.setIsloggedIn(true)

          // Means the user has a selected clinic
          if (response.user?.clinic) {
            const clinicStore = useClinicStore()

            // We need to pick the image from the clinics and not from the user
            const selectedClinic = response.clinics.find(
              (clinic) => clinic.id === response.user?.clinic?.id,
            )

            clinicStore.setClinic({
              ...response.user.clinic,
              image: selectedClinic?.image,
            })
          }
        })
        .catch(() => {
          this.logout()
        })
    },

    // For SPA only
    nuxtClientInit() {
      this.getUser()
    },
  },
})
