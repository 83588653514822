import type { FormKitNode } from '@formkit/core'
import {
  createAutoAnimatePlugin,
  createAutoHeightTextareaPlugin,
  createMultiStepPlugin,
} from '@formkit/addons'
import { pt } from '@formkit/i18n'
import { genesisIcons } from '@formkit/icons'
import {
  autocomplete,
  colorpicker,
  createProPlugin,
  currency,
  datepicker,
  mask,
  repeater,
  toggle,
  togglebuttons,
} from '@formkit/pro'
import { defineFormKitConfig } from '@formkit/vue'
import { rootClasses } from './formkit.theme.js'
import '@formkit/addons/css/multistep'

const proPlugin = createProPlugin('fk-3ccb58d62e', {
  autocomplete,
  repeater,
  datepicker,
  colorpicker,
  currency,
  mask,
  toggle,
  togglebuttons,
})

/**
 * Automatically add an asterisk to required fields.
 */

const legends = ['checkbox_multi', 'radio_multi', 'repeater', 'transferlist']

function addAsteriskPlugin(node: FormKitNode) {
  if (
    ['button', 'submit', 'hidden', 'group', 'list', 'meta'].includes(
      node.props.type,
    )
  ) {
    return
  }

  node.on('created', () => {
    const legendOrLabel = legends.includes(
      `${node.props.type}${node.props.options ? '_multi' : ''}`,
    )
      ? 'legend'
      : 'label'

    if (!node.props.definition) return

    if (node.props.definition.schemaMemoKey) {
      node.props.definition.schemaMemoKey += `${node.props.options ? '_multi' : ''}_add_asterisk`
    }

    const schemaFn = node.props.definition.schema
    node.props.definition.schema = (sectionsSchema = {}) => {
      sectionsSchema[legendOrLabel] = {
        children: [
          '$label',
          {
            $el: 'span',
            if: '$state.required',
            attrs: {
              class: '$classes.asterisk',
            },
            children: ['*'],
          },
        ],
      }

      return (schemaFn as (sectionsSchema: any) => any)(sectionsSchema) || {}
    }
  })
}

export default defineFormKitConfig({
  config: {
    rootClasses,
  },
  icons: {
    ...genesisIcons,
  },
  locales: { pt },
  locale: 'pt',
  plugins: [
    proPlugin,
    createAutoAnimatePlugin(),
    createMultiStepPlugin(),
    createAutoHeightTextareaPlugin(),
    addAsteriskPlugin,
  ],
})
