import type { ApiFetch } from './main'

export interface MiscAPI {
  generateJustification: (data: {
    patient: string
    start: string
    end: string
    page_size: string
    reasons: string
    observations: string
  }) => Promise<Blob>
}

export default (apiFetch: ApiFetch): MiscAPI => ({
  generateJustification(data) {
    return apiFetch(
      `/generate/justification?patient_id=${data.patient}&start=${data.start}&end=${data.end}&page_size=${data.page_size}`,
      {
        method: 'POST',
        body: {
          reasons: data.reasons,
          observations: data.observations,
        },
      },
    )
  },
})
