import payload_plugin_oi7RiaeDOK from "/opt/buildhome/repo/node_modules/.pnpm/@pinia+nuxt@0.7.0_magicast@0.3.5_rollup@4.25.0_typescript@5.6.3_vue@3.5.12_typescript@5.6.3__webpack-sources@3.2.3/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_d6GNA4sFex from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.9.0_eslint@9.14.0_jiti@2.4.0__ioredis@5.4._wa3pjwmiriypeyxdobuzw2khni/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_wT4oeatlSr from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.9.0_eslint@9.14.0_jiti@2.4.0__ioredis@5.4._wa3pjwmiriypeyxdobuzw2khni/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_eUGdm4YZkB from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.9.0_eslint@9.14.0_jiti@2.4.0__ioredis@5.4._wa3pjwmiriypeyxdobuzw2khni/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_GXGRHcobdU from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.9.0_eslint@9.14.0_jiti@2.4.0__ioredis@5.4._wa3pjwmiriypeyxdobuzw2khni/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_E4gGTSp9H1 from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.9.0_eslint@9.14.0_jiti@2.4.0__ioredis@5.4._wa3pjwmiriypeyxdobuzw2khni/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_O78Fu93lVc from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.9.0_eslint@9.14.0_jiti@2.4.0__ioredis@5.4._wa3pjwmiriypeyxdobuzw2khni/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_6f0k5HBBU2 from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.9.0_eslint@9.14.0_jiti@2.4.0__ioredis@5.4._wa3pjwmiriypeyxdobuzw2khni/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_2ZWskWUYmK from "/opt/buildhome/repo/node_modules/.pnpm/@pinia+nuxt@0.7.0_magicast@0.3.5_rollup@4.25.0_typescript@5.6.3_vue@3.5.12_typescript@5.6.3__webpack-sources@3.2.3/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/opt/buildhome/repo/.nuxt/components.plugin.mjs";
import prefetch_client_jXLPQyfkIq from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.9.0_eslint@9.14.0_jiti@2.4.0__ioredis@5.4._wa3pjwmiriypeyxdobuzw2khni/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import slideovers_Q7FxdhhKZT from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+ui@2.19.2_change-case@5.4.4_magicast@0.3.5_rollup@4.25.0_vite@5.4.10_@types+node@22.9.0_rqi7dkuwmtzlefdzrwqfm5wfau/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.js";
import modals_cIFkx7e3Do from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+ui@2.19.2_change-case@5.4.4_magicast@0.3.5_rollup@4.25.0_vite@5.4.10_@types+node@22.9.0_rqi7dkuwmtzlefdzrwqfm5wfau/node_modules/@nuxt/ui/dist/runtime/plugins/modals.js";
import colors_9gVuFIzqTq from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+ui@2.19.2_change-case@5.4.4_magicast@0.3.5_rollup@4.25.0_vite@5.4.10_@types+node@22.9.0_rqi7dkuwmtzlefdzrwqfm5wfau/node_modules/@nuxt/ui/dist/runtime/plugins/colors.js";
import plugin_client_yXm34t5y6f from "/opt/buildhome/repo/node_modules/.pnpm/@nuxtjs+color-mode@3.5.2_magicast@0.3.5_rollup@4.25.0_webpack-sources@3.2.3/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_mwzxuER8Po from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+icon@1.7.0_magicast@0.3.5_rollup@4.25.0_vite@5.4.10_@types+node@22.9.0_sass@1.80.6_ters_uosbeqf52cyr27obdnykxnvyue/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import formkitPlugin_pZqjah0RUG from "/opt/buildhome/repo/.nuxt/formkitPlugin.mjs";
import pwa_icons_plugin_LnrhIJeMG7 from "/opt/buildhome/repo/.nuxt/pwa-icons-plugin.ts";
import pwa_client_eI991x5xyO from "/opt/buildhome/repo/node_modules/.pnpm/@vite-pwa+nuxt@0.10.6_@vite-pwa+assets-generator@0.2.6_magicast@0.3.5_rollup@4.25.0_vite@5.4._e32y4cmzuzas77epr7hwiopb2a/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.js";
import _01_api_GIiciWaEWX from "/opt/buildhome/repo/plugins/01.api.ts";
import _02_nuxtClientInit_client_42PBK3TMYu from "/opt/buildhome/repo/plugins/02.nuxtClientInit.client.ts";
import dayjs_pGiXRjcsJO from "/opt/buildhome/repo/plugins/dayjs.ts";
import sentry_client_shVUlIjFLk from "/opt/buildhome/repo/plugins/sentry.client.ts";
export default [
  payload_plugin_oi7RiaeDOK,
  revive_payload_client_d6GNA4sFex,
  unhead_wT4oeatlSr,
  router_eUGdm4YZkB,
  payload_client_GXGRHcobdU,
  navigation_repaint_client_E4gGTSp9H1,
  check_outdated_build_client_O78Fu93lVc,
  chunk_reload_client_6f0k5HBBU2,
  plugin_vue3_2ZWskWUYmK,
  components_plugin_KR1HBZs4kY,
  prefetch_client_jXLPQyfkIq,
  slideovers_Q7FxdhhKZT,
  modals_cIFkx7e3Do,
  colors_9gVuFIzqTq,
  plugin_client_yXm34t5y6f,
  plugin_mwzxuER8Po,
  formkitPlugin_pZqjah0RUG,
  pwa_icons_plugin_LnrhIJeMG7,
  pwa_client_eI991x5xyO,
  _01_api_GIiciWaEWX,
  _02_nuxtClientInit_client_42PBK3TMYu,
  dayjs_pGiXRjcsJO,
  sentry_client_shVUlIjFLk
]