import type { ApiFetch } from './main'
import type { ApiResponse } from '~/types/misc'
import type {
  ClinicStats,
  DashboardStats,
  PatientsWithNegativeBalanceStats,
} from '~/types/stats'

export interface StatsApi {
  getClinicStats: (
    from: string,
    to: string,
    widgetAppointmentCarer?: string | null,
  ) => Promise<ClinicStats>
  getDashboardStats: () => Promise<DashboardStats>
  getPatientsWithNegativeBalanceStats: (
    offset?: number,
    limit?: number,
  ) => Promise<ApiResponse<PatientsWithNegativeBalanceStats>>
}

export default (apiFetch: ApiFetch): StatsApi => ({
  getClinicStats(from, to, widgetAppointmentCarer) {
    let url = `/stats/clinic?`

    if (from) {
      url += `date_from=${from}&`
    }

    if (to) {
      url += `date_to=${to}&`
    }

    if (widgetAppointmentCarer) {
      url += `appointment_carer=${widgetAppointmentCarer}&`
    }

    // Remove the trailing '&' or '?' if no parameters were added
    url = url.endsWith('&') || url.endsWith('?') ? url.slice(0, -1) : url

    return apiFetch(url)
  },

  getDashboardStats() {
    return apiFetch(`/stats/dashboard`)
  },

  getPatientsWithNegativeBalanceStats(offset, limit) {
    let url = `/stats/patients?`

    if (offset) {
      url += `offset=${offset}&`
    }

    if (limit) {
      url += `limit=${limit}&`
    }

    // Remove the trailing '&' or '?' if no parameters were added
    url = url.endsWith('&') || url.endsWith('?') ? url.slice(0, -1) : url

    return apiFetch(url)
  },
})
