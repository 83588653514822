// This is not a real middleware, but a function that is used in the middleware
import type { Permission } from '~/types/misc'

export default function hasPermission(permission: Permission[]) {
  const userStore = useUserStore()

  // Wait for the user to be loaded
  userStore.$subscribe(() => {
    // Admins have all permissions
    if (userStore.isClinicAdmin) return

    // Check if the user has all the required permissions
    const hasAllPermissions = permission.every((perm) =>
      userStore.hasPermission(perm),
    )

    if (!hasAllPermissions) {
      const router = useRouter()
      showToast(
        'error',
        'Acesso Negado',
        'Você não tem permissão para aceder a esta página',
      )
      return router.push('/')
    }
  })
}
