import type { ApiFetch } from './main'
import type { ForgotPassword } from '~/types/user'

export interface AuthAPI {
  login: (data: { email: string; password: string }) => Promise<void>
  logout: () => Promise<void>
  signupFirstAccount: (data: {
    email: string
    first_name: string
    last_name: string
    password: string
    color: string
  }) => Promise<void>
  askChangeEmail: (data: { email: string }) => Promise<void>
  changeEmail: (data: { token: string }) => Promise<void>
  askResetPassword: (data: { email: string }) => Promise<ForgotPassword | void>
  changePassword: (data: {
    token: string
    new_password: string
  }) => Promise<void>
  inviteStaff: (
    email: string,
    permissions?: string[],
    isAdmin?: boolean,
  ) => Promise<void>
  acceptInviteAccount: (
    token: string,
    data: {
      first_name: string
      last_name: string
      password: string
      color: string
    },
  ) => Promise<void>
}

export default (apiFetch: ApiFetch): AuthAPI => ({
  login(data) {
    return apiFetch(`/auth/login`, {
      method: 'POST',
      body: data,
    })
  },

  logout() {
    return apiFetch(`/auth/logout`, {
      method: 'POST',
    })
  },

  signupFirstAccount(data) {
    return apiFetch(`/auth/signup`, {
      method: 'POST',
      body: data,
    })
  },

  askResetPassword(data) {
    return apiFetch(`/auth/ask-reset-password`, {
      method: 'POST',
      body: data,
    })
  },

  changePassword(data) {
    return apiFetch(`/auth/change-password`, {
      method: 'POST',
      body: data,
    })
  },

  askChangeEmail(data) {
    return apiFetch(`/auth/ask-change-email`, {
      method: 'POST',
      body: data,
    })
  },

  changeEmail(data) {
    return apiFetch(`/auth/change-email`, {
      method: 'POST',
      body: data,
    })
  },

  inviteStaff(email, permissions = [], isAdmin = false) {
    let body: { email: string; permissions?: string[]; is_admin?: boolean } = {
      email,
    }

    if (isAdmin) {
      body = { ...body, is_admin: isAdmin }
    } else {
      body = { ...body, permissions }
    }

    return apiFetch(`/invite`, {
      method: 'POST',
      body,
    })
  },

  acceptInviteAccount(token, data) {
    return apiFetch(`/invite/signup?token=${token}`, {
      method: 'POST',
      body: data,
    })
  },
})
