<script lang="ts" setup>
interface Props {
  type: 'success' | 'warning' | 'error'
  title: string
  description: string
  confirmIcon?: string
  confirmText: string
  cancelText?: string
  input?: {
    placeholder: string
  }
  select?: {
    placeholder: string
    options: any
  }
}

const props = withDefaults(defineProps<Props>(), {
  cancelText: 'Cancelar',
})

const emit = defineEmits(['success', 'cancel', 'input', 'select'])

const input = ref('')
const select = ref('')
const isLoading = ref(false)

const icon = computed(() => {
  switch (props.type) {
    case 'success':
      return {
        name: 'i-heroicons-check-circle',
        color: 'text-green-500',
      }
    case 'warning':
      return {
        name: 'i-heroicons-exclamation-circle',
        color: 'text-amber-500',
      }
    case 'error':
      return {
        name: 'i-heroicons-x-circle',
        color: 'text-red-500',
      }

    default:
      return {
        name: 'i-heroicons-x-circle',
        color: 'text-red-500',
      }
  }
})

function onCancel() {
  emit('cancel')
}

function onSuccess() {
  if (props.input?.placeholder && !input.value)
    return showToast('error', 'Erro', 'Por favor, preencha o campo.')

  if (props.select?.placeholder && !select.value)
    return showToast('error', 'Erro', 'Por favor, selecione uma opção.')

  isLoading.value = true
  emit('success')
}

// If has input, emit
watch(
  () => input.value,
  () => {
    if (props.input?.placeholder) emit('input', input.value)
  },
)

// If has select, emit
watch(
  () => select.value,
  () => {
    if (props.select?.placeholder) emit('select', select.value)
  },
)
</script>

<template>
  <UModal>
    <UCard
      :ui="{
        body: {
          padding: 'p-0 sm:p-0',
        },
      }"
    >
      <div
        class="flex flex-col items-center space-y-3 px-4 py-3 text-center sm:px-6"
      >
        <UIcon :name="icon.name" class="mt-8 size-24" :class="icon.color" />

        <h2 class="text-2xl font-bold text-gray-800">{{ title }}</h2>
        <p class="text-gray-500">{{ description }}</p>

        <template v-if="props.select?.placeholder">
          <USelect
            v-model="select"
            :placeholder="props.select.placeholder"
            :options="props.select.options"
            class="w-full"
          />
        </template>

        <template v-if="props.input?.placeholder && select === 'Outra razão'">
          <UInput
            v-model="input"
            :placeholder="props.input.placeholder"
            class="w-full"
          />
        </template>
      </div>

      <div
        class="mt-8 flex w-full justify-end space-x-3 rounded-b-lg bg-gray-50 px-4 py-3 sm:px-6"
      >
        <UButton
          :loading="isLoading"
          :label="confirmText"
          color="red"
          :icon="confirmIcon"
          @click="onSuccess"
        />

        <UButton
          :label="cancelText"
          color="red"
          variant="soft"
          icon="i-heroicons-x-mark"
          @click="onCancel"
        />
      </div>
    </UCard>
  </UModal>
</template>
