<script lang="ts" setup>
useHead({
  titleTemplate: (title?: string) =>
    title
      ? `${title} | DentalNext`
      : 'DentalNext | Gestão de Clínicas Dentárias',
})

usePwaNotification()

const online = useOnline()
watch(online, () => {
  if (online.value) {
    showToast('success', 'Online', 'Você está online.')
  } else {
    showToast('error', 'Offline', 'Por favor, verifique a sua ligação.')
  }
})
</script>

<template>
  <NuxtPwaAssets />
  <NuxtLoadingIndicator color="var(--primary)" />
  <NuxtLayout>
    <NuxtPage />
    <UNotifications />
    <UModals />
  </NuxtLayout>
</template>
