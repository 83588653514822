import type { ApiFetch } from './main'

export interface PaymentApi {
  createCheckoutSession: () => Promise<{ client_secret: string }>
  checkCheckoutSession: (sessionId: string) => Promise<{ is_valid: boolean }>
  createCustomerPortal: () => Promise<{ url: string }>
}

export default (apiFetch: ApiFetch): PaymentApi => ({
  createCheckoutSession() {
    return apiFetch(`/payments/checkout-session`, {
      method: 'POST',
    })
  },

  checkCheckoutSession(sessionId) {
    return apiFetch(`/payments/checkout-session?session_id=${sessionId}`, {
      method: 'GET',
    })
  },

  createCustomerPortal() {
    return apiFetch(`/payments/customer-portal`, {
      method: 'POST',
    })
  },
})
