import { BaseModal } from '#components'

type StateType =
  | 'success'
  | 'error'
  | 'warning'
  | 'info'
  | 'update'
  | 'security'
  | 'reminder'

enum NotificationColor {
  Green = 'green',
  Red = 'red',
  Orange = 'orange',
  Primary = 'primary',
}

function defineIcon(state: StateType) {
  let icon
  let color

  switch (state) {
    case 'success':
      icon = 'i-heroicons-check-circle'
      color = NotificationColor.Green
      break
    case 'error':
      icon = 'i-heroicons-x-circle'
      color = NotificationColor.Red
      break
    case 'warning':
      icon = 'i-heroicons-exclamation-triangle'
      color = NotificationColor.Orange
      break
    case 'info':
    case 'update':
      icon = 'i-heroicons-information-circle'
      color = NotificationColor.Primary
      break
    case 'security':
      icon = 'i-heroicons-shield-exclamation'
      color = NotificationColor.Red
      break
    case 'reminder':
      icon = 'i-heroicons-exclamation-circle'
      color = NotificationColor.Red
      break
    default:
      icon = 'i-heroicons-information-circle'
      color = NotificationColor.Primary
      break
  }

  return { icon, color }
}

export function showToast(
  state: StateType,
  title?: string,
  description?: string | { response: { _data: { detail: string } } },
  timeout: number = 5000,
  actions?: any,
) {
  const toast = useToast()
  const { icon, color } = defineIcon(state)

  if (typeof description === 'object') {
    description = description?.response?._data?.detail ?? undefined

    if (description?.includes('CSRF')) {
      description = 'Sessão expirada. Por favor, faça login novamente.'
    }
  }

  toast.add({
    title,
    description,
    icon,
    color,
    timeout,
    actions,
  })
}

export async function leaveRouteModal(): Promise<boolean> {
  const modal = useModal()

  return new Promise((resolve) => {
    modal.open(BaseModal, {
      type: 'warning',
      title: 'Você tem alterações por guardar.',
      description: 'Tem a certeza que deseja sair?',
      confirmText: 'Sair',
      confirmIcon: 'i-heroicons-arrow-left-end-on-rectangle-solid',
      preventClose: true,
      onCancel() {
        modal.close()
        resolve(false)
      },
      onSuccess() {
        modal.close()
        resolve(true)
      },
    })
  })
}
