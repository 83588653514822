import { defineStore } from 'pinia'
import type { AuthClinic, UserClinicStore } from '~/types/clinic'
import type { User } from '~/types/user'

export const useClinicStore = defineStore('clinic', {
  state: (): UserClinicStore => ({
    clinic: undefined,
    staff: [],
    websocket: {
      connected: false,
      last_appointment_change: undefined,
      last_patient_change: undefined,
      last_stock_change: undefined,
      last_invoice_change: undefined,
      last_notification_change: undefined,
      last_reminder_change: undefined,
    },
  }),

  actions: {
    setClinic(data: AuthClinic) {
      this.clinic = data
    },

    setStaff(data: User[]) {
      for (const staff of data) {
        staff.full_name = `${staff.first_name} ${staff.last_name}`
      }
      this.staff = data
    },

    setWebsocketConnection(connected: boolean) {
      this.websocket.connected = connected
    },

    setLastAppointmentChange() {
      this.websocket.last_appointment_change = new Date()
    },

    setLastPatientChange() {
      this.websocket.last_patient_change = new Date()
    },

    setLastStockChange() {
      this.websocket.last_stock_change = new Date()
    },

    setLastInvoiceChange() {
      this.websocket.last_invoice_change = new Date()
    },

    setLastNotificationChange() {
      this.websocket.last_notification_change = new Date()
    },

    setLastReminderChange() {
      this.websocket.last_reminder_change = new Date()
    },

    resetWebsocketConnection() {
      this.websocket = {
        connected: false,
        last_appointment_change: undefined,
        last_patient_change: undefined,
        last_stock_change: undefined,
        last_invoice_change: undefined,
        last_notification_change: undefined,
        last_reminder_change: undefined,
      }
    },
  },
})
