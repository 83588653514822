export default defineAppConfig({
  ui: {
    primary: 'sky',

    avatar: {
      background: 'bg-sky-100 dark:bg-sky-800',
      rounded: 'rounded-full object-cover',
      size: {
        '3xl': 'h-24 w-24 text-3xl',
      },
    },

    card: {
      base: '',
      header: {
        padding: 'p-4 sm:px-6',
      },
      body: {
        // It's being used to add user-select: text for muuri
        base: 'card-body',
      },
    },

    notifications: {
      position: 'right-0 top-16 bottom-auto lg:top-20 lg:right-4',
      description: 'mt-1 text-sm text-gray-500 dark:text-gray-400',
    },

    badge: {
      default: {
        variant: 'soft',
      },
    },

    button: {
      default: {
        size: 'lg',
      },
    },

    skeleton: {
      background: 'bg-gray-200 dark:bg-gray-700',
    },

    table: {
      td: {
        base: 'whitespace-nowrap align-middle',
        color: 'text-gray-700 dark:text-gray-400',
      },
    },
  },
})
