import type { Api } from '~/api/main'
import mainAPI from '~/api/main'

declare module '#app' {
  interface NuxtApp {
    $api: Api
  }
}

const whitelistedRoutes = new Set(['clinic-add', 'clinic-configure'])

export default defineNuxtPlugin((nuxtApp) => {
  const config = useRuntimeConfig()
  const userStore = useUserStore()
  const router = useRouter()

  const apiFetch = $fetch.create({
    credentials: 'include',
    baseURL: config.public.apiEndpoint,
    onRequest: (request) => {
      if (
        userStore.isLoggedIn &&
        !localStorage.getItem('clinic') &&
        !whitelistedRoutes.has(router.currentRoute.value.name?.toString() ?? '')
      ) {
        refreshToHome()
      }

      // Keep it like this to always have a fresh localStorage value
      request.options.headers = {
        // @ts-expect-error - This is a custom header
        'X-CLINIC-ID': localStorage.getItem('clinic') ?? '',
      }

      // Add 'X-CSRFToken' header for 'POST', 'PATCH', and 'PUT' requests
      if (
        ['POST', 'PATCH', 'PUT', 'DELETE'].includes(
          request.options.method?.toUpperCase() ?? '',
        )
      ) {
        const cookie = document.cookie
          .split('; ')
          .find((row) => row.startsWith('csrftoken'))
          ?.split('=')[1]

        // @ts-expect-error - This is a custom header
        request.options.headers['X-CSRFToken'] = cookie ?? ''
      }
    },
  })
  nuxtApp.provide('api', mainAPI(apiFetch))
})
