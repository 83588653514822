export default defineNuxtRouteMiddleware((to) => {
  const userStore = useUserStore()

  const commonRoutes = new Set(['200', '400', 'verify-account'])

  const allowedRoutes = new Set([
    'login',
    'register',
    'invite',
    'view-appointment',
    'api',
    'forgot-password',
    'change-password',
    'confirm-email',
    'verify-account',
  ])

  // Wait for the user to be loaded
  userStore.$subscribe(() => {
    if (!userStore.isLoggedIn && !allowedRoutes.has(to.name as string)) {
      return navigateTo('/login/', { redirectCode: 401 })
    }

    if (
      userStore.isLoggedIn &&
      allowedRoutes.has(to.name as string) &&
      !commonRoutes.has(to.name as string)
    ) {
      return navigateTo('/')
    }
  })
})
