<script setup lang="ts">
const error = useError()
const handleError = () => clearError({ redirect: '/' })
</script>

<template>
  <div
    v-if="error"
    class="flex min-h-screen flex-col items-center justify-center px-6 py-24 text-center sm:py-32 lg:px-8"
  >
    <p class="font-semibold text-sky-600">{{ error.statusCode }}</p>

    <h1
      class="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl"
    >
      <span v-if="error.statusCode === 404">Página não encontrada</span>
      <span v-else-if="error.statusCode === 401">Página não autorizada</span>
      <span v-else> Ocorreu um erro </span>
    </h1>

    <p class="mt-6 leading-7 text-gray-600">
      <span v-if="error.statusCode === 401">
        Caso tenha acesso a esta página
        <button @click="refreshToHome">clique aqui.</button> Caso o erro
        permaneça, contacte a equipa de suporte.
      </span>

      <span v-else> Caso o erro persista, contacte a equipa de suporte. </span>
    </p>

    <i class="mt-2 leading-7 text-gray-600">{{ error.statusMessage }}</i>

    <UButton
      v-if="error.statusCode !== 500"
      label="Ir para a página inicial"
      class="mt-4"
      @click="handleError()"
    />

    <UButton
      v-if="error.statusCode === 500"
      label="Reiniciar a Aplicação"
      class="mt-4"
      @click="refreshToHome()"
    />
  </div>
</template>
