import { default as change_45passwordrqPu8yxkMFMeta } from "/opt/buildhome/repo/pages/(auth)/change-password.vue?macro=true";
import { default as confirm_45emailZk5ql31qb0Meta } from "/opt/buildhome/repo/pages/(auth)/confirm-email.vue?macro=true";
import { default as forgot_45passwordTQ2gUhCnbsMeta } from "/opt/buildhome/repo/pages/(auth)/forgot-password.vue?macro=true";
import { default as login1khvQxAXTNMeta } from "/opt/buildhome/repo/pages/(auth)/login.vue?macro=true";
import { default as logoutVnsheXfEYxMeta } from "/opt/buildhome/repo/pages/(auth)/logout.vue?macro=true";
import { default as registerhDKBJ5PWQAMeta } from "/opt/buildhome/repo/pages/(auth)/register.vue?macro=true";
import { default as verify_45accountpOZFSzn6xNMeta } from "/opt/buildhome/repo/pages/(auth)/verify-account.vue?macro=true";
import { default as calendaryPechgWogzMeta } from "/opt/buildhome/repo/pages/calendar.vue?macro=true";
import { default as chatcdcDIyUuf8Meta } from "/opt/buildhome/repo/pages/chat.vue?macro=true";
import { default as addyiZ5AzF2iOMeta } from "/opt/buildhome/repo/pages/clinic/add.vue?macro=true";
import { default as configureCZkCibs1cHMeta } from "/opt/buildhome/repo/pages/clinic/configure.vue?macro=true";
import { default as indexniDiYCWjuTMeta } from "/opt/buildhome/repo/pages/index.vue?macro=true";
import { default as addUmH7AM98fEMeta } from "/opt/buildhome/repo/pages/invoices/add.vue?macro=true";
import { default as indexpbFZTYRhOcMeta } from "/opt/buildhome/repo/pages/invoices/index.vue?macro=true";
import { default as appointmentsMVPByafYqkMeta } from "/opt/buildhome/repo/pages/patients/[id]/appointments.vue?macro=true";
import { default as editkMRX5j52IyMeta } from "/opt/buildhome/repo/pages/patients/[id]/edit.vue?macro=true";
import { default as indexiifJK6ljz4Meta } from "/opt/buildhome/repo/pages/patients/[id]/index.vue?macro=true";
import { default as invoicesiirfI7RdAoMeta } from "/opt/buildhome/repo/pages/patients/[id]/invoices.vue?macro=true";
import { default as notes674M3qg4o7Meta } from "/opt/buildhome/repo/pages/patients/[id]/notes.vue?macro=true";
import { default as addLKK7TjhszNMeta } from "/opt/buildhome/repo/pages/patients/add.vue?macro=true";
import { default as indexpFWx8Nbg5nMeta } from "/opt/buildhome/repo/pages/patients/index.vue?macro=true";
import { default as reportsXblRqgf8cKMeta } from "/opt/buildhome/repo/pages/reports.vue?macro=true";
import { default as accountlrlzqC4yMWMeta } from "/opt/buildhome/repo/pages/settings/account.vue?macro=true";
import { default as clinicj5BDlp3qsNMeta } from "/opt/buildhome/repo/pages/settings/clinic.vue?macro=true";
import { default as delete5TRtiEKmMHMeta } from "/opt/buildhome/repo/pages/staff/[id]/delete.vue?macro=true";
import { default as index7OSV50tKZdMeta } from "/opt/buildhome/repo/pages/staff/[id]/index.vue?macro=true";
import { default as indexArdT9SfrpDMeta } from "/opt/buildhome/repo/pages/staff/index.vue?macro=true";
import { default as stockdf5XnmKC5MMeta } from "/opt/buildhome/repo/pages/stock.vue?macro=true";
import { default as view_45appointmenteisqVvSHSlMeta } from "/opt/buildhome/repo/pages/view-appointment.vue?macro=true";
export default [
  {
    name: "change-password",
    path: "/change-password",
    meta: change_45passwordrqPu8yxkMFMeta || {},
    component: () => import("/opt/buildhome/repo/pages/(auth)/change-password.vue")
  },
  {
    name: "confirm-email",
    path: "/confirm-email",
    meta: confirm_45emailZk5ql31qb0Meta || {},
    component: () => import("/opt/buildhome/repo/pages/(auth)/confirm-email.vue")
  },
  {
    name: "forgot-password",
    path: "/forgot-password",
    meta: forgot_45passwordTQ2gUhCnbsMeta || {},
    component: () => import("/opt/buildhome/repo/pages/(auth)/forgot-password.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: login1khvQxAXTNMeta || {},
    component: () => import("/opt/buildhome/repo/pages/(auth)/login.vue")
  },
  {
    name: "logout",
    path: "/logout",
    meta: logoutVnsheXfEYxMeta || {},
    component: () => import("/opt/buildhome/repo/pages/(auth)/logout.vue")
  },
  {
    name: "register",
    path: "/register",
    meta: registerhDKBJ5PWQAMeta || {},
    component: () => import("/opt/buildhome/repo/pages/(auth)/register.vue")
  },
  {
    name: "verify-account",
    path: "/verify-account",
    meta: verify_45accountpOZFSzn6xNMeta || {},
    component: () => import("/opt/buildhome/repo/pages/(auth)/verify-account.vue")
  },
  {
    name: "calendar",
    path: "/calendar",
    component: () => import("/opt/buildhome/repo/pages/calendar.vue")
  },
  {
    name: "chat",
    path: "/chat",
    component: () => import("/opt/buildhome/repo/pages/chat.vue")
  },
  {
    name: "clinic-add",
    path: "/clinic/add",
    meta: addyiZ5AzF2iOMeta || {},
    component: () => import("/opt/buildhome/repo/pages/clinic/add.vue")
  },
  {
    name: "clinic-configure",
    path: "/clinic/configure",
    meta: configureCZkCibs1cHMeta || {},
    component: () => import("/opt/buildhome/repo/pages/clinic/configure.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/opt/buildhome/repo/pages/index.vue")
  },
  {
    name: "invoices-add",
    path: "/invoices/add",
    meta: addUmH7AM98fEMeta || {},
    component: () => import("/opt/buildhome/repo/pages/invoices/add.vue")
  },
  {
    name: "invoices",
    path: "/invoices",
    meta: indexpbFZTYRhOcMeta || {},
    component: () => import("/opt/buildhome/repo/pages/invoices/index.vue")
  },
  {
    name: "patients-id-appointments",
    path: "/patients/:id()/appointments",
    component: () => import("/opt/buildhome/repo/pages/patients/[id]/appointments.vue")
  },
  {
    name: "patients-id-edit",
    path: "/patients/:id()/edit",
    meta: editkMRX5j52IyMeta || {},
    component: () => import("/opt/buildhome/repo/pages/patients/[id]/edit.vue")
  },
  {
    name: "patients-id",
    path: "/patients/:id()",
    component: () => import("/opt/buildhome/repo/pages/patients/[id]/index.vue")
  },
  {
    name: "patients-id-invoices",
    path: "/patients/:id()/invoices",
    meta: invoicesiirfI7RdAoMeta || {},
    component: () => import("/opt/buildhome/repo/pages/patients/[id]/invoices.vue")
  },
  {
    name: "patients-id-notes",
    path: "/patients/:id()/notes",
    component: () => import("/opt/buildhome/repo/pages/patients/[id]/notes.vue")
  },
  {
    name: "patients-add",
    path: "/patients/add",
    meta: addLKK7TjhszNMeta || {},
    component: () => import("/opt/buildhome/repo/pages/patients/add.vue")
  },
  {
    name: "patients",
    path: "/patients",
    component: () => import("/opt/buildhome/repo/pages/patients/index.vue")
  },
  {
    name: "reports",
    path: "/reports",
    meta: reportsXblRqgf8cKMeta || {},
    component: () => import("/opt/buildhome/repo/pages/reports.vue")
  },
  {
    name: "settings-account",
    path: "/settings/account",
    meta: accountlrlzqC4yMWMeta || {},
    component: () => import("/opt/buildhome/repo/pages/settings/account.vue")
  },
  {
    name: "settings-clinic",
    path: "/settings/clinic",
    meta: clinicj5BDlp3qsNMeta || {},
    component: () => import("/opt/buildhome/repo/pages/settings/clinic.vue")
  },
  {
    name: "staff-id-delete",
    path: "/staff/:id()/delete",
    meta: delete5TRtiEKmMHMeta || {},
    component: () => import("/opt/buildhome/repo/pages/staff/[id]/delete.vue")
  },
  {
    name: "staff-id",
    path: "/staff/:id()",
    component: () => import("/opt/buildhome/repo/pages/staff/[id]/index.vue")
  },
  {
    name: "staff",
    path: "/staff",
    component: () => import("/opt/buildhome/repo/pages/staff/index.vue")
  },
  {
    name: "stock",
    path: "/stock",
    meta: stockdf5XnmKC5MMeta || {},
    component: () => import("/opt/buildhome/repo/pages/stock.vue")
  },
  {
    name: "view-appointment",
    path: "/view-appointment",
    meta: view_45appointmenteisqVvSHSlMeta || {},
    component: () => import("/opt/buildhome/repo/pages/view-appointment.vue")
  }
]