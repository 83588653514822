import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration.js'
import isBetween from 'dayjs/plugin/isBetween.js'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter.js'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore.js'
import isToday from 'dayjs/plugin/isToday.js'
import localizedFormat from 'dayjs/plugin/localizedFormat.js'
import relativeTime from 'dayjs/plugin/relativeTime.js'
import utc from 'dayjs/plugin/utc.js'
import weekOfYear from 'dayjs/plugin/weekOfYear.js'
import 'dayjs/locale/pt'

declare module '#app' {
  interface NuxtApp {
    $dayjs: typeof dayjs
  }
}

export default defineNuxtPlugin({
  parallel: true,
  async setup(nuxtApp) {
    dayjs.locale('pt')
    dayjs.extend(utc)
    dayjs.extend(localizedFormat)
    dayjs.extend(duration)
    dayjs.extend(relativeTime)
    dayjs.extend(isToday)
    dayjs.extend(isSameOrAfter)
    dayjs.extend(isSameOrBefore)
    dayjs.extend(isBetween)
    dayjs.extend(weekOfYear)
    nuxtApp.provide('dayjs', dayjs)
  },
})
