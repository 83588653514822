import type { AppointmentsAPI } from './appointment'
import type { AuthAPI } from './auth'
import type { InvoicesAPI } from './invoice'
import type { MiscAPI } from './misc'
import type { NotificationApi } from './notification'
import type { PatientAPI } from './patient'
import type { PaymentApi } from './payment'
import type { StatsApi } from './stats'
import type { StockAPI } from './stock'
import type { UserAPI } from './user'
import appointmentsApi from './appointment'
import authApi from './auth'
import clinicApi, { type ClinicApi } from './clinic'
import invoicesApi from './invoice'
import miscApi from './misc'
import notificationApi from './notification'
import patientApi from './patient'
import paymentApi from './payment'
import statsApi from './stats'
import stockApi from './stock'
import userApi from './user'

export type ApiFetch = (
  url: string,
  options?: {
    method?: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE'
    body?: any
  },
) => Promise<any>

export interface Api
  extends AuthAPI,
    UserAPI,
    ClinicApi,
    PatientAPI,
    AppointmentsAPI,
    StockAPI,
    InvoicesAPI,
    NotificationApi,
    PaymentApi,
    StatsApi,
    MiscAPI {}

export default (apiFetch: ApiFetch): Api => ({
  ...authApi(apiFetch),
  ...userApi(apiFetch),
  ...clinicApi(apiFetch),
  ...patientApi(apiFetch),
  ...appointmentsApi(apiFetch),
  ...stockApi(apiFetch),
  ...invoicesApi(apiFetch),
  ...notificationApi(apiFetch),
  ...paymentApi(apiFetch),
  ...statsApi(apiFetch),
  ...miscApi(apiFetch),
})
