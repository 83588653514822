import type { RouterConfig } from '@nuxt/schema'

export default <RouterConfig>{
  scrollBehavior() {
    const main = document.querySelectorAll('main')[0]
    if (main) {
      main.scrollTop = 0
    }
  },
}
