import { version } from '@/package.json'
import {
  browserTracingIntegration,
  init,
  replayIntegration,
} from '@sentry/browser'

export default defineNuxtPlugin({
  parallel: true,
  async setup() {
    if (import.meta.dev) return
    init({
      dsn: 'https://b6afb07b30254472b0e9f71be97fc36a@o1298601.ingest.sentry.io/6529748',
      integrations: [
        browserTracingIntegration(),
        replayIntegration({
          maskAllText: true,
          blockAllMedia: true,
        }),
      ],
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,
      sampleRate: 1,
      tracesSampleRate: 1,
      release: version,
    })
  },
})
