import type { ApiFetch } from './main'
import type { User, UserRootObject } from '~/types/user'

export interface UserAPI {
  getCurrentUser: () => Promise<UserRootObject>
  updateCurrentUser: (data: User) => Promise<void>
  updateCurrentUserPicture: (data: FormData) => Promise<void>
  getStaff: () => Promise<User[]>
  getStaffUser: (id: string) => Promise<User>
  updateUserPermissions: (
    id: string,
    permissions: string[],
    changeToAdmin: boolean,
  ) => Promise<void>
  sendConfirmationToDeleteUser: (id: string) => Promise<void>
  deleteUser: (id: string, token: string) => Promise<void>
}

export default (apiFetch: ApiFetch): UserAPI => ({
  getCurrentUser() {
    return apiFetch(`/users/me`)
  },

  updateCurrentUser(data) {
    return apiFetch(`/users/me`, {
      method: 'PUT',
      body: data,
    })
  },

  updateCurrentUserPicture(data) {
    return apiFetch(`/users/me/upload-profile-picture`, {
      method: 'POST',
      body: data,
    })
  },

  getStaff() {
    return apiFetch(`/users`)
  },

  getStaffUser(id) {
    return apiFetch(`/users/${id}`)
  },

  updateUserPermissions(id, permissions, changeToAdmin = false) {
    const body = changeToAdmin ? { is_admin: true } : { permissions }

    return apiFetch(`/users/${id}/update-permissions`, {
      method: 'POST',
      body,
    })
  },

  sendConfirmationToDeleteUser(id) {
    return apiFetch(`/users/${id}/delete`, {
      method: 'POST',
    })
  },

  deleteUser(id, token) {
    return apiFetch(`/users/${id}?token=${token}`, {
      method: 'DELETE',
    })
  },
})
